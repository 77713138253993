import { ButtonIcon } from "../../buttons";
import { InputAdornment } from "@mui/material";
import { purge } from "@utils/purge";
import { useFormContext } from "../use-form-context";
import { useSelect } from "./context";
import { useWatch } from "react-hook-form";
import { IoChevronDown, IoClose } from "react-icons/io5";

export const SelectEndAdornment = (): React.ReactElement => {
  const { name } = useSelect();

  const { control, setValue } = useFormContext();

  const value = useWatch({
    control,
    name,
  });

  return (
    <InputAdornment position="start">
      <div className="grid grid-cols-[max-content,max-content] items-center w-full">
        {purge({ value }).value ? (
          <ButtonIcon
            size="small"
            onClick={(event) => {
              event.stopPropagation();

              setValue(name, [], {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            }}
          >
            <IoClose className="cursor-pointer text-tiny" />
          </ButtonIcon>
        ) : null}
        <ButtonIcon size="small">
          <IoChevronDown className="cursor-pointer text-tiny" />
        </ButtonIcon>
      </div>
    </InputAdornment>
  );
};
