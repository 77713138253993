/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext, useContext } from "react";

type SelectContextProps = {
  name: string;
  onChange?: (value?: unknown) => void;
  getOptionLabel: (data: any) => string;
  handleClose: () => void;
  limit: number;
  onLimit?: () => void;
};

const SelectContext = createContext<SelectContextProps | null>(null);

if ("development" === process.env.NODE_ENV) {
  SelectContext.displayName = "SelectContext";
}

type SelectProviderProps = {
  name: string;
  onChange?: (value?: unknown) => void;
  getOptionLabel: (data: any) => string;
  children?: React.ReactNode;
  handleClose: () => void;
  limit: number;
  onLimit?: () => void;
};

const SelectProvider = ({
  name,
  limit,
  onLimit,
  onChange,
  children,
  handleClose,
  getOptionLabel,
}: SelectProviderProps): React.ReactElement => (
  <SelectContext.Provider
    value={{ name, getOptionLabel, handleClose, limit, onLimit, onChange }}
  >
    {children}
  </SelectContext.Provider>
);

const useSelect = (): SelectContextProps => {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error("useSelect must be used within a SelectProvider");
  }

  return context;
};

export { SelectProvider, useSelect };
