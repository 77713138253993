/* eslint-disable @typescript-eslint/no-unsafe-return */
import { lazy as reactLazy } from "react";

const lazy = <
  T extends React.ComponentType<unknown>,
  I extends { [K2 in K]: T },
  K extends keyof I
>(
  factory: () => Promise<I>,
  name: K
): I => {
  return Object.create({
    [name]: reactLazy(() =>
      factory().then((module) => ({ default: module[name] }))
    ),
  });
};

export { lazy };
