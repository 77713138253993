import timezone from "dayjs/plugin/timezone";
import { useCallback } from "react";
import { useCurrentUser } from "@context/auth";
import utc from "dayjs/plugin/utc";
import dayjs, { Dayjs } from "dayjs";

const useUTCDate = (): {
  currentDate: () => Dayjs;
  dateFromUTC: (date: Date | string) => Dayjs;
} => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { timezone: tz } = useCurrentUser();

  const currentDate = useCallback(
    (): Dayjs =>
      tz?.timezone
        ? dayjs().utc().utcOffset(dayjs().tz(tz.timezone).utcOffset())
        : dayjs(),
    [tz?.timezone]
  );

  const dateFromUTC = useCallback(
    (date: Date | string): Dayjs => {
      return tz?.timezone
        ? dayjs.utc(date).utcOffset(dayjs(date).tz(tz.timezone).utcOffset())
        : dayjs(date);
    },
    [tz?.timezone]
  );

  return { currentDate, dateFromUTC };
};

export { useUTCDate };
