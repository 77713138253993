import { Tooltip } from "../../tooltip";
import { purge } from "@utils/purge";
import { useFormContext } from "../use-form-context";
import { useSelect } from "./context";
import { useWatch } from "react-hook-form";
import { Chip, InputAdornment } from "@mui/material";

type SelectAdornmentProps = {
  startIcon?: React.ReactNode;
  classNames: Partial<{
    chipLabel: string;
  }>;
};

export const SelectStartAdornment = ({
  startIcon,
  classNames,
}: SelectAdornmentProps): React.ReactElement => {
  const { name, getOptionLabel } = useSelect();

  const { control } = useFormContext();

  const value = useWatch({
    control,
    name,
  });

  return (
    <InputAdornment position="start">
      <div className="grid grid-cols-[max-content,max-content] items-center w-full">
        {startIcon ? <div className="mr-2">{startIcon}</div> : null}
        {value && Array.isArray(value) && value.length >= 1 ? (
          <Tooltip
            title={value
              ?.map((value) => (value ? getOptionLabel(value) : ""))
              .join(", ")}
            className="max-w-[15rem]"
            placement="top"
          >
            <Chip
              size="small"
              className={`text-white bg-black text-sm w-full ${
                classNames.chipLabel ?? ""
              }`}
              label={
                value.length === 1
                  ? getOptionLabel(value[0])
                  : "Multiple options..."
              }
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={purge({ value }).value ? getOptionLabel(value) : ""}
            className="max-w-[15rem]"
            placement="top"
          >
            <p
              className={`text-[#505050] text-[12px] w-full truncate ${
                classNames.chipLabel ?? ""
              }`}
            >
              {value ? getOptionLabel(value) : ""}
            </p>
          </Tooltip>
        )}
      </div>
    </InputAdornment>
  );
};
