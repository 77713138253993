import { DialogActions } from "@mui/material";
import { twMerge } from "tailwind-merge";

type ActionsProps = {
  className?: string;
  children: React.ReactNode;
};

export const Actions = ({
  className,
  children,
}: ActionsProps): React.ReactElement => (
  <DialogActions className={twMerge("m-0 bg-white", className)}>
    {children}
  </DialogActions>
);
