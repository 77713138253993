import { Chip } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";
import { useFormContext } from "../use-form-context";
import { useSelect } from "./context";
import { useWatch } from "react-hook-form";
import { isEqual, remove } from "lodash";

export const Chips = (): React.ReactElement | null => {
  const { name, getOptionLabel } = useSelect();

  const { control, setValue } = useFormContext();
  const list = useWatch({
    control,
    name,
  }) as Array<unknown>;

  return list && Array.isArray(list) && list.length > 0 ? (
    <div className="py-2 flex-row">
      {list.map((value, index) => (
        <Chip
          key={index}
          size="medium"
          color="primary"
          className="text-xs w-max max-w-[70%] truncate m-1 rounded-md text-left"
          label={getOptionLabel(value)}
          classes={{
            root: "bg-gray-100 text-gray-800",
            label: "text-sm",
          }}
          deleteIcon={
            <IoCloseOutline className="text-base text-gray-800 min-w-[1rem]" />
          }
          onDelete={() =>
            setValue(
              name,
              remove(list, (item) => !isEqual(item, value))
            )
          }
        />
      ))}
    </div>
  ) : null;
};
