import { Actions } from "./actions";
import { Content } from "./content";
import { Dialog } from "@mui/material";
import { Fragment } from "react";
import { Title } from "./title";

type ModalProps = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  open: boolean;
  classNames?: Partial<{
    paper: string;
    container: string;
    title: string;
    content: string;
    actions: string;
  }>;
  onClose: () => void;
};

export const Modal = ({
  title,
  content,
  actions,
  open,
  classNames,
  onClose,
}: ModalProps): React.ReactElement => (
  <Dialog
    aria-labelledby="customized-dialog"
    open={open}
    onClose={onClose}
    className={classNames?.container}
    classes={{
      paper: classNames?.paper,
    }}
  >
    <Fragment>
      {title ? (
        <Title className={classNames?.title} onClose={onClose}>
          {title}
        </Title>
      ) : null}
      {content ? (
        <Content className={classNames?.content}>{content}</Content>
      ) : null}
      {actions ? (
        <Actions className={classNames?.actions}>{actions}</Actions>
      ) : null}
    </Fragment>
  </Dialog>
);
