type FullPageErrorFallbackProps = {
  error: Error;
};

export const FullPageErrorFallback = ({
  error,
}: FullPageErrorFallbackProps): React.ReactElement => {
  return (
    <div
      role="alert"
      className="flex h-screen flex-col items-center justify-center text-red"
    >
      <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
};
