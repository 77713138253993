import { useFormContext } from "../use-form-context";
import { useSelect } from "./context";
import { useWatch } from "react-hook-form";
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { concat, isEqual, remove } from "lodash";

type MultipleOptionProps = {
  value: unknown;
  children?: React.ReactNode;
};

const MultipleOptionInner = ({
  children,
  value,
}: MultipleOptionProps): React.ReactElement => {
  const { name, getOptionLabel, limit, onLimit, onChange } = useSelect();

  const { control, setValue } = useFormContext();

  const emptyList = useMemo(() => [], []);

  const list =
    (useWatch({
      control,
      name,
    }) as Array<unknown>) || emptyList;

  const [checked, setChecked] = useState(
    !!list?.find((item) => isEqual(item, value))
  );

  useEffect(() => {
    setChecked(!!list?.find((item) => isEqual(item, value)));
  }, [list, value]);

  return (
    <ListItem disablePadding className="w-[inherit]">
      <ListItemButton
        className="w-[inherit]"
        onChange={onChange}
        onClick={() => {
          setChecked(() => {
            const newList = !checked
              ? list.length === limit
                ? list
                : concat(list, value)
              : remove(list, (item) => !isEqual(item, value));

            setValue(
              name,
              newList,

              { shouldValidate: true, shouldTouch: true }
            );

            onChange ? onChange(newList) : null;
            if (list.length === limit && onLimit) {
              onLimit();

              return checked;
            }

            return !checked;
          });
        }}
        dense
      >
        <ListItemIcon className="min-w-0">
          <Checkbox
            edge="start"
            size="small"
            checked={checked}
            tabIndex={-1}
            disableRipple
            className="text-gray-500"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            children ?? (
              <p className="truncate w-full leading-none text-gray-500">
                {value ? getOptionLabel(value) : ""}
              </p>
            )
          }
          className="max-w-[13rem]"
        />
      </ListItemButton>
    </ListItem>
  );
};

export const MultipleOption = memo(MultipleOptionInner);
