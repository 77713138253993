import { Fragment } from "react";
import { IoCheckmark } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import { Controller, useFormContext } from "react-hook-form";
import { Listbox as HeadessListBox, Transition } from "@headlessui/react";

type ListboxProps<TData extends Record<string, unknown>> = {
  name: string;
  options: Array<TData>;
  classNames?: {
    container?: string;
    button?: string;
    options?: string;
    option?: string;
    label?: string;
  };
  defaultValue?: TData;
  getOptionLabel: (option: TData) => string;
  buttonIcon?: React.ReactElement;
  onClickOption?: (value?: TData | undefined) => void;
};

export const Listbox = <TData extends Record<string, unknown>>({
  name,
  options,
  buttonIcon,
  classNames,
  defaultValue,
  onClickOption,
  getOptionLabel,
}: ListboxProps<TData>): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <HeadessListBox
          value={value as TData}
          onChange={(value: TData) => {
            onChange(value);

            if (onClickOption) {
              onClickOption(value);
            }
          }}
        >
          <div
            className={twMerge(
              "mt-1 relative w-full bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm",
              classNames?.container
            )}
          >
            <HeadessListBox.Button
              className={twMerge(
                "w-full text-left py-2 pl-3 pr-10",
                classNames?.button
              )}
            >
              <span className={`block truncate ${classNames?.label ?? ""}`}>
                {getOptionLabel(value)}
              </span>
              {buttonIcon ? (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  {buttonIcon}
                </span>
              ) : null}
            </HeadessListBox.Button>
            <Transition
              as={Fragment}
              leaveTo="opacity-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leave="ease-in duration-200"
              enter="ease-out duration-300"
            >
              <HeadessListBox.Options
                className={twMerge(
                  "absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                  classNames?.options
                )}
              >
                {options.map((option, index) => (
                  <HeadessListBox.Option
                    key={index}
                    value={option}
                    className={twMerge(
                      "cursor-pointer select-none relative py-2 pl-4 pr-4 hover:bg-black/10 text-gray-900",
                      classNames?.option
                    )}
                  >
                    {({ selected }) => (
                      <Fragment>
                        <span
                          className={twMerge(
                            "block truncate font-normal",
                            selected ? "font-medium pl-4" : null
                          )}
                        >
                          {getOptionLabel(option)}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoCheckmark
                              className="w-3 h-3"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </Fragment>
                    )}
                  </HeadessListBox.Option>
                ))}
              </HeadessListBox.Options>
            </Transition>
          </div>
        </HeadessListBox>
      )}
      defaultValue={defaultValue ?? null}
      name={name}
    />
  );
};
