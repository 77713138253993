import { MdClose } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { DialogTitle, IconButton } from "@mui/material";

type TitleProps = {
  className?: string;
  children: React.ReactNode;
  onClose?: () => void;
};

export const Title = ({
  className,
  children,
  onClose,
}: TitleProps): React.ReactElement => (
  <DialogTitle className={twMerge("bg-white m-0", className)}>
    <div className="flex justify-between items-center">
      {children}

      {onClose ? (
        <IconButton
          component="span"
          aria-label="close"
          className=" text-gray-500"
          onClick={onClose}
        >
          <MdClose className="text-base font-medium" />
        </IconButton>
      ) : null}
    </div>
  </DialogTitle>
);
