import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Button as ButtonMaterial } from "@mui/material";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonBaseProps, appearances, variant } from "./common";

const Button = forwardRef(
  (
    {
      children,
      "aria-label": ariaLabel,
      startIcon: StartIcon,
      endIcon: EndIcon,
      appearance = "primary",
      type = "submit",
      size = "small",
      onClick,
      className,
      status = "default",
    }: Omit<ButtonBaseProps, "appearance"> & {
      appearance?: "primary" | "secondary" | "link";
    },
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => (
    <ButtonMaterial
      aria-label={ariaLabel}
      size={size}
      disabled={status === "default" ? false : true}
      className={twMerge(
        appearances[appearance][0],
        appearances[appearance][1][status],
        "transition-colors items-center justify-center border",
        className
      )}
      onClick={onClick}
      endIcon={
        status === "loading" ? (
          <AiOutlineLoading3Quarters className="h-4 w-4 animate-spin p-0 m-0" />
        ) : (
          EndIcon
        )
      }
      startIcon={StartIcon}
      type={type}
      ref={ref}
      variant={variant[appearance]}
    >
      {children}
    </ButtonMaterial>
  )
);

if (process.env.NODE_ENV === "development") {
  Button.displayName = "Button";
}

export { Button };
