import { LoadAttr } from "@services/load";
import { createContext, useContext, useState } from "react";

type NewLoadAttr = {
  data: LoadAttr;
  status: "pending" | "history";
};

type LoadsRealtimeContextState = {
  data: Array<NewLoadAttr>;
  setData: React.Dispatch<React.SetStateAction<Array<NewLoadAttr>>>;
};

const LoadsRealtimeContext = createContext<LoadsRealtimeContextState | null>(
  null
);

if (process.env.NODE_ENV === "development") {
  LoadsRealtimeContext.displayName = "LoadsRealtimeContext";
}

type LoadsRealtimeProviderProps = {
  children?: React.ReactNode;
};

const LoadsRealtimeProvider = ({
  children,
}: LoadsRealtimeProviderProps): React.ReactElement => {
  const [data, setData] = useState<Array<NewLoadAttr>>([]);

  return (
    <LoadsRealtimeContext.Provider value={{ data, setData }}>
      {children}
    </LoadsRealtimeContext.Provider>
  );
};

const useLoadsRealtime = (): LoadsRealtimeContextState => {
  const context = useContext(LoadsRealtimeContext);

  if (context === null) {
    throw new Error("useFetch must be used within a LoadsRealtimeProvider");
  }

  return context;
};

export { LoadsRealtimeProvider, useLoadsRealtime };
