import { publicRequest } from "@utils/public-request";
import { useAsync } from "@utils/hooks/use-async";
import { useUTCDate } from "./use-utc-date";
// eslint-disable-next-line import/no-namespace -- This is to avoid auth-provider methods collapsing with auth-context methods
import * as authProvider from "@utils/auth-provider";
import { useEffect, useMemo } from "react";

type MaintenanceI = {
  active: boolean;
  block_all: boolean;
  created_at: Date;
  description: string;
  functionalities_to_block: Array<{
    permission_id: string;
    name: string;
    description: string;
  }>;
  maintenance_date: string;
  maintenance_id: number;
  portal: string;
  tentative_duration: number;
  updated_at: string;
  warning_date: string;
};

type MaintenanceAttr = {
  data: MaintenanceI;
};

const MAINTENANCE_ENDPOINT = `api/v1/maintenances/next?portal=${
  process.env.REACT_APP_PORTAL_IDENTIFIER ?? "quote-portal"
}`;

const getMaintenances = async (access_token: string): Promise<MaintenanceI> => {
  const response = await publicRequest.get<MaintenanceAttr>(
    MAINTENANCE_ENDPOINT,
    {
      baseURL: process.env.REACT_APP_API_URL_COMMONS,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );

  return response.data.data;
};

const maintenancesData = async (): Promise<MaintenanceI | null> => {
  const auth = authProvider.getAuthFromCache();

  if (auth) {
    try {
      const data = getMaintenances(auth.access_token);

      return data;
    } catch (error) {
      authProvider.removeAuthInCache();

      return null;
    }
  }

  return null;
};

type UseMaintenanceTimesT = {
  isCurrentMaintenance: boolean;
  data: MaintenanceI | null | undefined;
};

const useMaintenanceTimes = (): UseMaintenanceTimesT => {
  const { dateFromUTC, currentDate } = useUTCDate();
  const { run, data } = useAsync<MaintenanceI | null>();
  const date = currentDate();

  useEffect(() => {
    run(maintenancesData());
  }, [run]);

  const isCurrentMaintenance = useMemo<boolean>((): boolean => {
    return Boolean(
      data &&
        date.isAfter(dateFromUTC(data.maintenance_date), "m") &&
        date.isBefore(
          dateFromUTC(data.maintenance_date).add(
            data.tentative_duration,
            "hours"
          ),
          "m"
        )
    );
  }, [data, date, dateFromUTC]);

  return {
    isCurrentMaintenance,
    data,
  };
};

export { useMaintenanceTimes, maintenancesData };
export type { MaintenanceI };
