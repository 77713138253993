import { ReactQueryDevtools } from "react-query/devtools";
import { useConstant } from "@utils/hooks";
import { QueryClient, QueryClientProvider as RQProvider } from "react-query";

type QueryClientProviderProps = {
  children: React.ReactNode;
};

const QueryClientProvider = ({
  children,
}: QueryClientProviderProps): React.ReactElement => {
  const queryClient = useConstant(() => {
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
        },
      },
    });

    return client;
  });

  return (
    <RQProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </RQProvider>
  );
};

export { QueryClientProvider };
