import { DialogContent } from "@mui/material";
import { twMerge } from "tailwind-merge";

type ContentProps = {
  className?: string;
  children: React.ReactNode;
};

export const Content = ({
  className,
  children,
}: ContentProps): React.ReactElement => (
  <DialogContent className={twMerge("bg-white", className)}>
    {children}
  </DialogContent>
);
