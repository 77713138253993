import { useAuth } from "./auth";
import axios, { AxiosInstance } from "axios";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { getAuthFromCache, refreshAuth } from "@utils/auth-provider";

const getAccessTokenFromCache = (): string =>
  getAuthFromCache(true).access_token;

const FetchContext = createContext<AxiosInstance | null>(null);

if (process.env.NODE_ENV === "development") {
  FetchContext.displayName = "FetchContext";
}

type FetchProviderProps = {
  children?: React.ReactNode;
};

const FetchProvider = ({
  children,
}: FetchProviderProps): React.ReactElement => {
  const accessTokenRef = useRef(getAccessTokenFromCache());
  const { user } = useAuth();

  const timezone = useMemo(() => {
    const offset =
      user?.timezone?.offset || -new Date().getTimezoneOffset() / 60;
    const minuteSide = offset % 1 !== 0 ? Math.abs(offset % 1) * 60 : 0;
    const hourSide = offset % 1 !== 0 ? Math.floor(offset) : offset;

    return `${hourSide >= 0 ? "+" : "-"}${
      Math.abs(hourSide) > 9 ? Math.abs(hourSide) : `0${Math.abs(hourSide)}`
    }:${minuteSide || "00"}`;
  }, [user?.timezone]);

  useEffect(() => {
    const refreshAuthTimeout = window.setInterval(
      () => {
        void refreshAuth().then(() => {
          accessTokenRef.current = getAccessTokenFromCache();
        });
      },
      // or 7 days
      parseFloat(process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION ?? "6.048e8")
    );

    return () => {
      window.clearInterval(refreshAuthTimeout);
    };
  }, []);

  const authRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authRequest.interceptors.request.use(
    (config) => ({
      ...config,
      headers: {
        Authorization: `Bearer ${accessTokenRef.current}`,
        "x-timezone": timezone,
      },
    }),
    (error) => Promise.reject(error)
  );

  return (
    <FetchContext.Provider value={authRequest}>
      {children}
    </FetchContext.Provider>
  );
};

const useFetch = (): AxiosInstance => {
  const context = useContext(FetchContext);

  if (context === null) {
    throw new Error("useFetch must be used within a FetchProvider");
  }

  return context;
};

export { FetchProvider, useFetch };
