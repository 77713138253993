import { twMerge } from "tailwind-merge";
import { uniqueId } from "lodash";
import { useState } from "react";
import { SpeedDial, SpeedDialAction } from "@mui/material";

type FloatingActionButtonProps = {
  options: Array<{
    icon: React.ReactElement;
    name: string;
    className?: string;
    onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }>;
  icon: React.ReactElement;
  classNames?: Partial<{
    container: string;
    fab: string;
    action: string;
  }>;
  direction?: "left" | "right" | "down" | "up";
};

export const FloatingActionButton = ({
  options,
  icon,
  classNames,
  direction = "down",
}: FloatingActionButtonProps): React.ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <SpeedDial
      ariaLabel="Floating action button"
      className={twMerge("shadow-none", classNames?.container)}
      FabProps={{
        className: twMerge(
          "w-5 h-5 min-h-0 min-w-max rounded-full shadow-none bg-transparent",
          classNames?.fab
        ),
      }}
      direction={direction}
      icon={icon}
      open={open}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
    >
      {options.map((action) => (
        <SpeedDialAction
          key={uniqueId("unique-option-")}
          icon={action.icon}
          tooltipTitle={action.name}
          className={classNames?.action}
          FabProps={{
            className: twMerge(
              "w-5 h-5 min-h-0 min-w-0 shadow-none rounded-full mt-1",
              action.className
            ),
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (action.onClick) {
              event.stopPropagation();
              action.onClick(event);
            }
          }}
        />
      ))}
    </SpeedDial>
  );
};
