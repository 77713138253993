import { styled } from "@mui/material/styles";
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";

export const Tooltip = styled(
  ({ className, title = " ", ...props }: TooltipProps) => (
    <MuiTooltip
      {...props}
      arrow
      title={title}
      classes={{ popper: className }}
    />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#27272a",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#27272a",
  },
  [`& .${tooltipClasses.popper}`]: {
    zIndex: 1500,
  },
}));
