import { useCallback, useState } from "react";

export const useDisclosure = (
  defaultOpen = false
): {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
} => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const close = useCallback(() => setIsOpen(false), []);

  const open = useCallback(() => setIsOpen(true), []);

  const toggle = useCallback(() => setIsOpen((state) => !state), []);

  return { isOpen, toggle, open, close };
};
