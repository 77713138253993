import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IconButton } from "@mui/material";
import { twMerge } from "tailwind-merge";
import { ButtonBaseProps, appearances } from "./common";

type ButtonIconProps = Omit<
  ButtonBaseProps,
  "startIcon" | "endIcon" | "appearance" | "size"
> & {
  loadingClassName?: string;
  size?: "small" | "medium";
};

export const ButtonIcon = ({
  children,
  className,
  status = "default",
  loadingClassName,
  onClick,
  type,
  size,
}: ButtonIconProps): React.ReactElement => {
  return (
    <IconButton
      size={size}
      onClick={onClick}
      type={type}
      disabled={status === "default" ? false : true}
      className={twMerge(
        appearances["icon"][0],
        appearances["icon"][1][status],
        "transition-colors items-center justify-center border font-medium",
        className
      )}
    >
      {status === "loading" ? (
        <AiOutlineLoading3Quarters
          className={twMerge(
            "h-4 w-4 animate-spin p-0 m-0 text-white",
            loadingClassName
          )}
        />
      ) : (
        children
      )}
    </IconButton>
  );
};
