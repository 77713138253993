import { version } from "../../package.json";
import { UseQueryResult, useQuery } from "react-query";

type AppDetails = {
  version: string;
};

const AppDetailsKeys = {
  all: ["app-details"] as const,
};

const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  const compare = versionsA.map((number, index) => {
    const a = Number(number);
    const b = Number(versionsB.length > index ? versionsB[index] : "");

    if (a !== b) return a > b || isNaN(b);

    return false;
  });

  return !!compare.find((value) => value === true);
};

const useAppDetails = (): UseQueryResult<AppDetails> => {
  return useQuery<AppDetails>(
    AppDetailsKeys.all,
    async () =>
      await fetch("/app.json").then(
        (response) => response.json() as unknown as AppDetails
      ),
    {
      refetchInterval: 120 * 60 * 1000,
      onSuccess: (data) => {
        if (semverGreaterThan(data.version, version)) {
          caches
            .keys()
            .then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            })
            .finally(() => window.location.reload());
        }
      },
    }
  );
};

export type { AppDetails };
export { useAppDetails };
