import { useAppDetails } from "@services/app";

export const AppDetailsWrapper = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  useAppDetails();

  return children;
};
