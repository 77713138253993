import { App } from "./app";
import { AppProviders } from "@context/intex";
import { StrictMode } from "react";
import { render } from "react-dom";
import { reportWebVitals } from "./report-web-vitals";
import "./bootstrap";
import "./index.css";

render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
  document.getElementById("root")
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
