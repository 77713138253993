import { twMerge } from "tailwind-merge";
import { AutocompleteRenderOptionState, Checkbox } from "@mui/material";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

type ItemWithCheckboxProps = {
  props: React.HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
  optionsLabel: string;
  multiple?: boolean;
};

export const Item = ({
  props,
  state,
  optionsLabel,
  multiple = false,
}: ItemWithCheckboxProps): React.ReactElement => {
  return (
    <li
      {...props}
      className={twMerge(
        // eslint-disable-next-line react/prop-types -- The property is passed by Material UI at runtime.
        props?.className,
        "text-sm cursor-pointer hover:bg-black/10 line-clamp-1 truncate block p-4"
      )}
    >
      {multiple ? (
        <Checkbox
          icon={<MdCheckBoxOutlineBlank />}
          checkedIcon={<MdCheckBox className="text-black" />}
          className="mr-2"
          checked={state.selected}
        />
      ) : null}
      {optionsLabel}
    </li>
  );
};
