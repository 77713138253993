import { BrowserAgent } from "@newrelic/browser-agent/src/loaders/browser-agent.js";
import { FetchProvider } from "@context/fetch";
import { FullPageSpinner } from "@edgelogistics/web-ui";
import { LoadsRealtimeProvider } from "@screens/shared/loads-realtime/new-data-context";
import { Toaster } from "react-hot-toast";
import { lazy } from "@utils/lazy-imports";
import { useAuth } from "@context/auth";
import { useFaviconByTheme } from "@utils/hooks/use-favicon-by-theme";
import { Fragment, Suspense, useEffect } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";

const { UnauthenticatedApp } = lazy(
  () =>
    import(
      /* webpackChunkName: "unauthenticated-app", webpackPrefetch: true */ "./unauthenticated-app"
    ),
  "UnauthenticatedApp"
);

const { AuthenticatedApp } = lazy(
  () =>
    import(
      /* webpackChunkName: "authenticated-app", webpackPrefetch: true */
      "./authenticated-app"
    ),
  "AuthenticatedApp"
);

export const App = (): React.ReactElement => {
  useFaviconByTheme();
  const { user } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_NEW_RELIC_APP_ID) {
      const newRelic = new BrowserAgent({
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: [process.env.REACT_APP_NEW_RELIC_BEACON] },
        },
        info: {
          beacon: process.env.REACT_APP_NEW_RELIC_BEACON,
          errorBeacon: process.env.REACT_APP_NEW_RELIC_BEACON,
          licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
          applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
          sa: 1,
        },
        loader_config: {
          accountID: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
          trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
          agentID: process.env.REACT_APP_NEW_RELIC_APP_ID,
          licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
          applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
        },
      });

      if (process.env.REACT_APP_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(newRelic.config);
      }
    }
  }, []);

  return (
    <Fragment>
      <Suspense
        fallback={<FullPageSpinner classNames={{ container: "z-20" }} />}
      >
        {user ? (
          <FetchProvider>
            <LoadsRealtimeProvider>
              <AuthenticatedApp />
            </LoadsRealtimeProvider>
          </FetchProvider>
        ) : (
          <UnauthenticatedApp />
        )}
      </Suspense>

      <Toaster
        position="bottom-center"
        toastOptions={{
          className: "text-sm max-w-2xl bg-black text-white",
          success: {
            icon: <MdCheckCircle className="h-5 w-5 text-white" />,
          },
          error: {
            icon: <MdCancel className="h-5 w-5 text-white" />,
          },
          id: "alert-message",
        }}
      />
    </Fragment>
  );
};
