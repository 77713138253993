import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { DateRange as MDateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { useFormContext } from "./use-form-context";
import { Controller, RegisterOptions } from "react-hook-form";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Fragment, useState } from "react";

type DateRangeProps = Partial<{
  rules: RegisterOptions;
  variant: "standard" | "filled" | "outlined";
  placeholder: string;
  label: React.ReactNode;
  startAdornment: React.ReactNode;
  required: boolean;
  disabled: boolean;
  clasNames?: {
    container: string;
  };
  rootInputProps: {
    className: string;
  };
}> & { name: string };

export const DateRange = ({
  name,
  label,
  rules,
  placeholder,
  clasNames,
  startAdornment,
  rootInputProps,
  variant = "standard",
}: DateRangeProps): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, isTouched },
      }) => (
        <FormControl
          error={Boolean(error && isTouched)}
          className={clasNames?.container}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              calendars={1}
              value={value as MDateRange<Date>}
              onChange={onChange}
              PopperProps={{ style: { zIndex: 1300 } }}
              open={open}
              onClose={() => setOpen(false)}
              renderInput={(startProps, endProps) => {
                const inputValue = `${startProps.inputProps?.value as string} ${
                  startProps.inputProps?.value ? " - " : ""
                } ${endProps.inputProps?.value as string}`.trim();

                return (
                  <Fragment>
                    <TextField
                      fullWidth
                      variant={variant}
                      label={label}
                      value={inputValue === "" ? null : inputValue}
                      onChange={() => null}
                      placeholder={placeholder}
                      onClick={() => setOpen(true)}
                      inputProps={rootInputProps}
                      InputProps={{
                        value: inputValue,
                        autoComplete: "off",
                        startAdornment,
                        placeholder: placeholder,
                        endAdornment: null,
                      }}
                    />
                  </Fragment>
                );
              }}
            />
          </LocalizationProvider>
          <FormHelperText className="text-xs font-semibold text-red ml-0">
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      defaultValue={[null, null]}
    />
  );
};
