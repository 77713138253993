import { IoCaretDown } from "react-icons/io5";
import { Item } from "./item-with-checkbox";
import { Tooltip } from "@components/tooltip";
import { uniqueId } from "lodash";
import { useFormContext } from "../use-form-context";
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { Controller, RegisterOptions } from "react-hook-form";
import { Autocomplete as MAutocomplete, TextField } from "@mui/material";

type AutoCompleteProps<TData extends Record<string, unknown>> = Partial<{
  rules: RegisterOptions;
  classNames: Partial<{
    input: string;
    container: string;
    autocomplete: string;
  }>;
  variant: "standard" | "filled" | "outlined";
  placeholder: string;
  label: React.ReactNode;
  inputProps: Partial<{
    startAdornment: React.ReactNode;
    endAdornment: React.ReactNode;
    className: string;
  }>;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TData,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  multiple: boolean;
  disableCloseOnSelect: boolean;
  autoComplete: boolean;
  loading: boolean;
  onInputChange: (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onFetchNextPage: () => void | null;
  required: boolean;
}> & {
  name: string;
  options: Array<TData>;
  getOptionLabel: (option: TData) => string;
};

export const Autocomplete = <TData extends Record<string, unknown>>({
  name,
  rules,
  label,
  options,
  variant,
  inputProps,
  classNames,
  placeholder,
  onInputChange,
  getOptionLabel,
  onFetchNextPage,
  loading = false,
  multiple = false,
  required = false,
  autoComplete = false,
  disableCloseOnSelect = true,
}: AutoCompleteProps<TData>): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, isTouched },
      }) => (
        <FormControl
          error={Boolean(error && isTouched)}
          className={classNames?.container}
        >
          <MAutocomplete
            openOnFocus
            value={value as TData | Array<TData> | null}
            loading={loading}
            options={options}
            multiple={multiple}
            autoComplete={autoComplete}
            classes={{
              popupIndicator: required ? "mr-2" : "",
            }}
            defaultValue={multiple ? [] : null}
            onChange={(e, v) => {
              e.preventDefault();
              e.stopPropagation();

              onChange(v);
            }}
            className={classNames?.autocomplete}
            disableCloseOnSelect={disableCloseOnSelect}
            isOptionEqualToValue={(option, value) =>
              getOptionLabel(option) === getOptionLabel(value)
            }
            getOptionLabel={getOptionLabel}
            renderTags={(_, getTagProps) => {
              if (!value) {
                return null;
              }

              const props = getTagProps({ index: 1 });

              return (
                <Tooltip
                  title={(value as Array<TData>)
                    .map((value) => getOptionLabel(value))
                    .join(", ")}
                  className="max-w-[15rem]"
                  placement="top"
                >
                  <Chip
                    {...props}
                    onDelete={undefined}
                    key={uniqueId("unique-tag-")}
                    className={`${props.className} bg-black text-white text-[.55rem] h-5 mr-0 max-w-[6rem] overflow-ellipsis whitespace-nowrap cursor-pointer`}
                    label={`${
                      (value as Array<TData>).length > 1
                        ? "Multiple options..."
                        : getOptionLabel((value as Array<TData>)[0])
                    }`}
                  />
                </Tooltip>
              );
            }}
            renderOption={(props, option, state) => (
              <Item
                props={props}
                state={state}
                multiple={multiple}
                optionsLabel={getOptionLabel(option)}
                key={`${getOptionLabel(option)}-${uniqueId("unique-item-")}`}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                error={Boolean(error && isTouched)}
                label={label}
                variant={variant}
                placeholder={placeholder}
                className={classNames?.input}
                InputLabelProps={{
                  required,
                  classes: {
                    asterisk: variant === "standard" ? "hidden" : "text-red",
                  },
                }}
                InputProps={{
                  ...inputProps,
                  ...params.InputProps,
                  className: inputProps?.className,
                  startAdornment:
                    inputProps?.startAdornment ||
                    params.InputProps.startAdornment ? (
                      <InputAdornment position="start">
                        {inputProps?.startAdornment}
                        {params.InputProps.startAdornment}
                      </InputAdornment>
                    ) : null,
                }}
              />
            )}
            popupIcon={<IoCaretDown className="text-tiny" />}
            onInputChange={onInputChange}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight &&
                  onFetchNextPage
                ) {
                  onFetchNextPage();
                }
              },
            }}
          />
          <FormHelperText
            className={`text-xs font-semibold text-red ml-0 truncate h-4 ${
              error?.message ? "visible" : "invisible"
            }`}
          >
            {error?.message}
          </FormHelperText>
          {required && variant === "standard" ? (
            <span className="text-sm text-red absolute top-4 right-0">*</span>
          ) : null}
        </FormControl>
      )}
      name={name}
      defaultValue={multiple ? [] : null}
    />
  );
};
