import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ButtonBaseProps, appearances, variant } from "./common";

type AnchorProps = Omit<ButtonBaseProps, "type" | "onClick" | "appearance"> & {
  to: string;
  appearance?: "primary" | "secondary" | "link";
  component: Link;
};

export const Anchor = ({
  to,
  size = "small",
  children,
  className,
  endIcon: EndIcon,
  startIcon: StartIcon,
  appearance = "primary",
  status = "default",
  component,
}: AnchorProps): React.ReactElement => {
  return (
    <Button
      to={to}
      size={size}
      disabled={status === "default" ? false : true}
      component={component}
      endIcon={
        status === "loading" ? (
          <AiOutlineLoading3Quarters className="h-4 w-4 animate-spin p-0 m-0" />
        ) : (
          EndIcon
        )
      }
      startIcon={StartIcon}
      className={twMerge(
        appearances[appearance][0],
        appearances[appearance][1][status],
        "transition-colors items-center justify-center border font-medium",
        className
      )}
      variant={variant[appearance]}
    >
      {children}
    </Button>
  );
};
