import { twMerge } from "tailwind-merge";

type ProgressBarProps = {
  value: number;
  color?: string;
  classNames?: Partial<{
    bar: string;
    container: string;
  }>;
};
export const ProgressBar = ({
  value,
  color,
  classNames,
}: ProgressBarProps): React.ReactElement => {
  return (
    <div className="relative pt-1 w-full">
      <div
        className={twMerge(
          "overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-500",
          classNames?.container
        )}
      >
        <div
          style={{
            minWidth: `${value}%`,
            backgroundColor: color,
          }}
          className={twMerge(
            "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-100",
            classNames?.bar
          )}
        ></div>
      </div>
    </div>
  );
};
