import { ResponseAttr } from "@services/common/types";
import { publicRequest } from "./public-request";

const AUTH_KEY = "_47cm272d34_1mib8yu~~!!@#E22s ./dmdjkniuyqc";
const AUTHENTICATION_ENDPOINT = "api/v1/auth";

type AuthAttr = {
  access_token: string;
  refresh_token?: string;
  user_id: string;
};

const removeAuthInCache = (): void => window.localStorage.removeItem(AUTH_KEY);

const setAuthInCache = (auth: AuthAttr): void =>
  window.localStorage.setItem(AUTH_KEY, JSON.stringify(auth));

/**
 *
 * @returns Retrieves the user's auth state from localStorage
 */
function getAuthFromCache(throwOnNoCache: true): AuthAttr;
function getAuthFromCache(throwOnNoCache?: false): AuthAttr | null;
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- Can't do overloading with arrow functions
function getAuthFromCache(throwOnNoCache = false): AuthAttr | null {
  const serializedAuthState = localStorage.getItem(AUTH_KEY);

  if (serializedAuthState) {
    return JSON.parse(serializedAuthState) as AuthAttr;
  }

  if (throwOnNoCache) {
    throw new Error(
      "throwOnNoCache = true must be used after a successful user's login"
    );
  }

  return null;
}

/**
 * @description Retrieves the user's auth information and creates a cache with it in localStorage
 */
const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AuthAttr> => {
  const params = new URLSearchParams();

  params.append("email", email);
  params.append("password", password);

  const response = await publicRequest.post<ResponseAttr<AuthAttr>>(
    `${AUTHENTICATION_ENDPOINT}/login`,
    params,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
  const auth = response.data.data;

  setAuthInCache(auth);

  return auth;
};

/**
 * @description Sends an email with a link to recover the user password
 */
const recoverPassword = async (email: string): Promise<void> => {
  await publicRequest.post<void>(`${AUTHENTICATION_ENDPOINT}/forgot-password`, {
    email,
  });
};

/**
 * @description Refresh the user's auth state. It must be used after a successful user's login
 */
const refreshAuth = async (): Promise<void> => {
  const auth = getAuthFromCache(true);

  const response = await publicRequest.post<
    ResponseAttr<Omit<AuthAttr, "user_id">>
  >(
    `${AUTHENTICATION_ENDPOINT}/refresh-token`,
    {
      refresh_token: auth.refresh_token,
    },
    {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  );

  const refreshedAuth = response.data.data;

  setAuthInCache({ ...refreshedAuth, user_id: auth.user_id });
};

export type { AuthAttr };
export {
  removeAuthInCache,
  setAuthInCache,
  getAuthFromCache,
  login,
  recoverPassword,
  refreshAuth,
};
