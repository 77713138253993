import { AppDetailsWrapper } from "@components/app-details-wrapper";
import { AuthProvider } from "./auth";
import { QueryClientProvider } from "./query-client";
import { BrowserRouter as Router } from "react-router-dom";

type AppProvidersProps = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: AppProvidersProps): React.ReactElement => (
  <QueryClientProvider>
    <AppDetailsWrapper>
      <Router>
        <AuthProvider>{children}</AuthProvider>
      </Router>
    </AppDetailsWrapper>
  </QueryClientProvider>
);

export { AppProviders };
