import { ResponseAttr } from "./common/types";
import { isNil } from "lodash";
import { ordersKey } from "./orders";
import { useAuth } from "@context/auth";
import { useFetch } from "@context/fetch";
import { weekCalendarKey } from "./week-calendar";
import { LocationAttr, TimezoneAttr } from "./common-data";
import {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

const USER_ENDPOINT = "api/v1/users";

const usersKey = {
  all: ["users"] as const,
  details: () => [...usersKey.all, "detail"] as const,
  current: () => [...usersKey.details(), "current"] as const,
};

type PermissionAttr = {
  permission_id: ScopeAttr;
  name: string;
  description: string;
};

type RoleAttr = {
  role_id: "customer" | "salesperson" | "admin";
  is_custom_role: boolean;
  name: string;
  description: string;
  permissions: Array<PermissionAttr>;
};

type ScopeAttr =
  | `auto_rate`
  | `write:quotes`
  | `read:${"all-customers-data" | "order-pod-bol-file"}`
  | `update:quotes-outcome-status`
  | `view:${
      | "dashboard-section"
      | "order-delivered-section"
      | "order-section"
      | "quote-section"}`;

type UserAttr = {
  customer_id?: string | null;
  salesperson_id?: string | null;
  user_id: string;
  email: string;
  carrier_id: string;
  auth0_id: string;
  phone_number: string;
  status: boolean;
  type: string;
  name: string;
  role_id: string;
  created_at: Date;
  updated_at: Date;
  role: RoleAttr;
  additional_permissions: Array<PermissionAttr>;
  timezone: TimezoneAttr;
  customer?: {
    additional_permissions: Array<PermissionAttr>;
    customer_id: string;
    name: string;
    salesperson_id: string;
    email: string;
    phone_number: string;
    timezone_id: number;
    location_id: number;
    timezone?: TimezoneAttr;
    location?: Pick<
      LocationAttr,
      "location_id" | "city" | "city_id" | "state" | "state_id"
    >;
  };
  timezone_id: number;
  avatar: string;
};

const useUser = (
  config?: UseQueryOptions<UserAttr>
): UseQueryResult<UserAttr> => {
  const authRequest = useFetch();

  return useQuery<UserAttr>(
    usersKey.current(),
    async () =>
      (await authRequest.get<ResponseAttr<UserAttr>>(`${USER_ENDPOINT}/me`))
        .data.data,
    config
  );
};

type UseUserContactParams = {
  phone_number: string;
  email: string;
};

const useUpdateUserContact = (): UseMutationResult<
  void,
  unknown,
  UseUserContactParams
> => {
  const authRequest = useFetch();
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UseUserContactParams) => {
      return (
        await authRequest.patch<void>(`${USER_ENDPOINT}/me/contact`, data)
      ).data;
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(usersKey.current());
      },
    }
  );
};

type UseUpdateUserParams = {
  files?: Blob;
  name: string;
  email: string;
  phone_number: string;
  timezone_id: string;
};

const useUpdateUser = (): UseMutationResult<
  { data: UserAttr },
  unknown,
  UseUpdateUserParams
> => {
  const authRequest = useFetch();
  const queryClient = useQueryClient();
  const { setData } = useAuth();

  return useMutation(
    async ({ files, ...meta }: UseUpdateUserParams) => {
      const data = new FormData();
      Object.entries(meta).forEach(([key, value]) => {
        if (isNil(value)) {
          return;
        }
        data.append(key, value as string | Blob);
      });
      if (files) {
        data.append("files", files, "files");
      }

      return (
        await authRequest.patch<{ data: UserAttr }>(
          `${USER_ENDPOINT}/me`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      ).data;
    },
    {
      onSuccess: (res: { data: UserAttr }) => {
        void queryClient.invalidateQueries(usersKey.current());
        void queryClient.invalidateQueries(weekCalendarKey.all);
        void queryClient.invalidateQueries(ordersKey.all);
        setData(res.data);
      },
    }
  );
};

export type { RoleAttr, ScopeAttr, UserAttr };
export { usersKey, useUser, useUpdateUser, useUpdateUserContact };
