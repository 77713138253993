import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ButtonBaseProps, appearances } from "./common";

type AnchorIconProps = Omit<
  ButtonBaseProps,
  "type" | "onClick" | "startIcon" | "endIcon" | "size" | "appearance"
> & {
  to: string;
  loadingClassName?: string;
  size?: "small" | "medium";
  component: Link;
};

export const AnchorIcon = ({
  to,
  children,
  className,
  status = "default",
  loadingClassName,
  size,
  component: Component,
}: AnchorIconProps): React.ReactElement => {
  return (
    <IconButton
      size={size}
      type="button"
      disabled={status === "default" ? false : true}
      className={twMerge(
        appearances["icon"][0],
        appearances["icon"][1][status],
        "transition-colors items-center justify-center border font-medium",
        className
      )}
    >
      <Component to={to}>
        {status === "loading" ? (
          <AiOutlineLoading3Quarters
            className={twMerge(
              "h-4 w-4 animate-spin p-0 m-0 text-white",
              loadingClassName
            )}
          />
        ) : (
          children
        )}
      </Component>
    </IconButton>
  );
};
