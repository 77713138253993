import { useEffect } from "react";
import { useThemeDetector } from "./use-theme-detector";

export const useFaviconByTheme = (): void => {
  const isDarkTheme = useThemeDetector();

  useEffect(() => {
    document
      .getElementById("favicon")
      ?.setAttribute(
        "href",
        `/edge-favicon-${isDarkTheme ? "white" : "dark"}.svg`
      );
  }, [isDarkTheme]);
};
