import { useFormContext } from "./use-form-context";
import { Controller, RegisterOptions } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Checkbox as MUICheckbox,
} from "@mui/material";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

type CheckboxProps = Partial<{
  rules: RegisterOptions;
  label: React.ReactNode;
  required: boolean;
  classNames?: Partial<{ checkbox: string; container: string }>;
}> & { name: string };

export const Checkbox = ({
  name,
  label,
  rules,
  classNames,
  required = false,
}: CheckboxProps): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, isTouched },
      }) => (
        <FormControl
          error={Boolean(error && isTouched)}
          className={classNames?.container}
        >
          <FormControlLabel
            control={
              <MUICheckbox
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={
                  <MdCheckBox
                    className={classNames?.checkbox ?? "text-black"}
                  />
                }
                checked={value as boolean}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.checked)
                }
                required={required}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <FormLabel
                className="text-sm ml-2 font-normal"
                error={Boolean(error && isTouched)}
                component="span"
                required={required}
              >
                {label}
              </FormLabel>
            }
          />
          <FormHelperText className="text-xs font-semibold text-red ml-0">
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
      name={name}
      defaultValue={false}
    />
  );
};
