type SkeletonProps = {
  height?: string;
  width?: string;
  color?: string;
};

/**
 * @param {string} height TailwindCSS height utility class
 * @param {string} width TailwindCSS width utility class
 * @param {string} color TailwindCSS bg utility class
 */
const Skeleton = ({
  height = "h-4",
  width = "w-full",
  color = "bg-gray-400",
}: SkeletonProps): React.ReactElement => (
  <div className={`${height} ${width} ${color} animate-pulse rounded`}></div>
);

type SkeletonCircleProps = SkeletonProps;

/**
 * @param {string} height TailwindCSS height utility class
 * @param {string} width TailwindCSS width utility class
 * @param {string} color TailwindCSS bg utility class
 */
const SkeletonCircle = ({
  height = "h-4",
  width = "w-4",
  color = "bg-gray-400",
}: SkeletonCircleProps): React.ReactElement => (
  <div
    className={`${height} ${width} ${color} animate-pulse rounded-full`}
  ></div>
);

export { Skeleton, SkeletonCircle };
