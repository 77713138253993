import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

type FullPageSpinnerProps = Partial<{
  classNames: Partial<{
    icon: string;
    container: string;
  }>;
}>;

export const FullPageSpinner = ({
  classNames,
}: FullPageSpinnerProps): React.ReactElement => (
  <div
    className={twMerge(
      "flex justify-center items-center h-screen w-screen bg-black",
      classNames?.container
    )}
  >
    <AiOutlineLoading3Quarters
      className={twMerge(
        "animate-spin text-primary-300 h-20 w-20 text-white",
        classNames?.icon
      )}
    />
  </div>
);
