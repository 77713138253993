const appearances = {
  primary: [
    "capitalize text-xs text-white border-black bg-black shadow-sm w-[12.8125rem] h-[1.5625rem]",
    {
      default: "hover:bg-gray-800",
      loading: "opacity-70 cursor-default",
      disabled: "opacity-70 cursor-not-allowed",
    },
  ],
  secondary: [
    "capitalize text-xs text-black border-black shadow-sm w-[12.8125rem] h-[1.5625rem]",
    {
      default: "hover:bg-gray-100",
      loading: "opacity-70 cursor-default",
      disabled:
        "opacity-70 bg-gray-600 border-black text-gray-200 cursor-not-allowed",
    },
  ],
  link: [
    "capitalize text-xs w-[12.8125rem] h-[1.5625rem]",
    {
      default: "hover:text-opacity-80 text-black",
      loading: "opacity-70 cursor-default",
      disabled: "opacity-70 cursor-not-allowed",
    },
  ],
  icon: [
    "capitalize text-black w-auto text-xs w-auto",
    {
      default: "hover:text-opacity-80 hover:bg-gray-100",
      loading: "opacity-70 cursor-default",
      disabled: "opacity-70 cursor-not-allowed",
    },
  ],
} as const;

const variant = {
  primary: "contained",
  secondary: "outlined",
  link: "text",
} as const;

type ButtonBaseProps = Partial<{
  "aria-label": string;
  children: React.ReactNode;
  startIcon: React.VFC<{ className: string }> | React.ReactNode;
  endIcon: React.VFC<{ className: string }> | React.ReactNode;
  appearance: keyof typeof appearances;
  className: string;
  type: "submit" | "button" | "reset";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size: "small" | "medium" | "large";
  status: keyof typeof appearances["primary"][1];
}>;

export { appearances, variant };
export type { ButtonBaseProps };
