import { AnyObjectSchema } from "yup";
import { forwardRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  ValidationMode,
  useForm,
} from "react-hook-form";

type FormProps<
  T extends Record<string, unknown>,
  TContext extends Record<string, unknown> = Record<string, unknown>
> = {
  defaultValues?: DefaultValues<T>;
  children:
    | ((methods: UseFormReturn<T, TContext>) => React.ReactElement)
    | React.ReactNode;
  onSubmit?: SubmitHandler<T>;
  schema?: AnyObjectSchema;
  className?: string;
  mode?: keyof ValidationMode;
};

const FormInner = <
  T extends Record<string, unknown>,
  TContext extends Record<string, unknown> = Record<string, unknown>
>(
  {
    defaultValues,
    children,
    onSubmit,
    mode = "onSubmit",
    schema,
    className,
  }: FormProps<T, TContext>,
  ref: React.Ref<HTMLFormElement>
): React.ReactElement => {
  const methods = useForm<T, TContext>({
    mode,
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form
        ref={ref}
        className={className}
        onSubmit={onSubmit ? methods.handleSubmit(onSubmit) : undefined}
      >
        {typeof children === "function" ? children(methods) : children}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(FormInner) as <
  T extends Record<string, unknown>,
  TContext extends Record<string, unknown> = Record<string, unknown>
>(
  props: FormProps<T, TContext> & {
    ref?: React.Ref<HTMLFormElement>;
  }
) => ReturnType<typeof FormInner>;
