import { ResponseAttr } from "./common/types";
import { downloadFile } from "@utils/download-file";
import { useFetch } from "@context/fetch";
import { EquipmentTypesAttr, LocationAttr } from "./common-data";
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";

const ORDER_ENDPOINT = "/api/v1/orders";

const ordersKey = {
  all: ["orders"] as const,
  infiniteLists: () => [...ordersKey.all, "infiniteList"] as const,
  ordersInfiniteList: (filters?: UseOrdersFilters) =>
    [...ordersKey.infiniteLists(), filters] as const,
  deliveredOrdersInfiniteList: (filters?: UseDeliveredOrdersFilters) =>
    [...ordersKey.infiniteLists(), filters, "delivered"] as const,
  details: () => [...ordersKey.all, "details"] as const,
  detail: (orderId: string) => [...ordersKey.details(), orderId] as const,
  trackingHistoryInfiniteLists: () =>
    [...ordersKey.infiniteLists(), "trackingHistory"] as const,
  trackingHistoryInfiniteList: (params?: UseTrackingHistoryParams) =>
    [...ordersKey.trackingHistoryInfiniteLists(), params] as const,
  ordersLanesList: (filters?: UseOrderLanesFilters) =>
    [...ordersKey.all, filters, "lanes"] as const,
  filesAvailable: (order_number?: string) =>
    [...ordersKey.all, "file", order_number] as const,
  orderStatus: () => [...ordersKey.all, "orderStatus"] as const,
};

type OrderAttr = {
  co2_emission_kg?: string;
  arrive_early_datetime: string;
  arrive_early_time_offset: number;
  arrive_early_time: string;
  arrive_late_time_offset: number;
  arrive_late_time: string;
  carrier_id: string | null;
  comments: string | null;
  commodity: string | null;
  distance: number;
  equipment_type: EquipmentTypesAttr;
  marked: number;
  movement_number: string;
  order_location: Partial<{
    city_name: string;
    latitude: number;
    located_at: string;
    longitude: number;
    state_id: string;
  }>;
  order_number: string;
  order_status: string | null;
  ordered_date: string;
  payment_date: string | null;
  po_number: string;
  pu_number: string;
  radius: number;
  rate: string | null;
  ship_date_offset: number | null;
  ship_date: string | null;
  shipment_id?: string | null;
  sort: string;
  status_descr: string;
  stops_count: number;
  stops: Array<OrderStopAttr>;
  trailer_length: number | null;
  type: "dedicated" | "single" | null;
  weight: number;
  status: {
    brokerage_status_description: string;
    brokerage_status: string;
    payment_date: string;
    status_code: string;
    status_description: string;
    progress_color: string;
    progress_percentage?: number;
    status_id: number;
    final_grouped_status: string;
  };
  origin: Pick<
    LocationAttr,
    "latitude" | "longitude" | "city" | "zip_code" | "state"
  > & {
    address: string;
    arrive_early_datetime_offset: number | undefined;
    arrive_early_datetime: string | undefined;
    arrive_late_datetime_offset: number | undefined;
    arrive_late_datetime: string | undefined;
    location: string;
    movement_number: string;
    state_code: string;
    stop_percentage: number;
    stop_id: string;
  };
  destination: Pick<
    LocationAttr,
    "latitude" | "longitude" | "city" | "zip_code" | "state"
  > & {
    address: string;
    arrive_early_datetime_offset: number | undefined;
    arrive_early_datetime: string | undefined;
    arrive_late_datetime_offset: number | undefined;
    arrive_late_datetime: string | undefined;
    location: string;
    movement_number: string;
    state_code: string;
    stop_percentage: number;
    stop_id: string;
  };
  dispatch: {
    dispatcher_id: string | null;
    dispatcher_name: string | null;
    dispatcher_email: string | null;
    dispatcher_phone_number: string | null;
  };
  driver: {
    driver_name: string | null;
    driver_phone_number: string | null;
    driver_email: string | null;
  };
  tractor: {
    trailer_number: string;
    tractor_number: string | null;
    equipment_type: string;
  };
  tractor_traveled_percentage: number;
  on_hold: boolean;
};

type OrderStopAttr = Pick<
  LocationAttr,
  "city" | "latitude" | "longitude" | "zip_code" | "state"
> & {
  address: string;
  arrive_early_datetime: string | undefined;
  arrive_early_datetime_offset: number | undefined;
  arrive_late_datetime: string | undefined;
  arrive_late_datetime_offset: number | undefined;
  location: string;
  sequence_number: number;
  state_code: string;
  stop_id: string;
  stop_type?: string;
  stop_percentage: number;
};

const useOrder = (
  id: string,
  config?: UseQueryOptions<OrderAttr>
): UseQueryResult<OrderAttr> => {
  const authRequest = useFetch();

  return useQuery<OrderAttr>(
    ordersKey.detail(id),
    async () =>
      (
        await authRequest.get<ResponseAttr<OrderAttr>>(
          `${ORDER_ENDPOINT}/${id}`
        )
      ).data.data,
    config
  );
};

type UseOrdersFilters = Partial<{
  reference: number;
  equipment_type: Array<string> | string;
  origin: Array<string> | string;
  destination: Array<string> | string;
  sort_by: string;
  order_by: "ASC" | "DESC";
  customer_id: string;
  arrive_lane_date_range: Array<Date | null>;
}>;

type OrderPageAttr = {
  data: Array<OrderAttr>;
  pagination: {
    count: number;
    limit: number;
    page: number;
  };
};

const useInfiniteOrders = (
  filters?: UseOrdersFilters,
  config?: UseInfiniteQueryOptions<OrderPageAttr>
): UseInfiniteQueryResult<OrderPageAttr> => {
  const authRequest = useFetch();

  return useInfiniteQuery<OrderPageAttr>(
    ordersKey.ordersInfiniteList(filters),
    async ({ pageParam: page = 1 }: { pageParam?: number }) => {
      const response = await authRequest.post<OrderPageAttr>(
        `${ORDER_ENDPOINT}`,
        {
          ...filters,
          status_to_exclude: ["D", "V"],
          page,
          limit: 10,
        }
      );

      return response.data;
    },
    {
      ...config,
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < Math.ceil(lastPage.pagination.count / 10)
          ? allPages.length + 1
          : undefined,
    }
  );
};

type UseDeliveredOrdersFilters = UseOrdersFilters;

type DeliveredOrderAttr = OrderAttr;

type DeliveredOrderPageAttr = {
  data: Array<DeliveredOrderAttr>;
  pagination: {
    count: number;
    limit: number;
    page: number;
  };
};

const useInfiniteDeliveredOrders = (
  filters?: UseDeliveredOrdersFilters,
  config?: UseInfiniteQueryOptions<DeliveredOrderPageAttr>
): UseInfiniteQueryResult<DeliveredOrderPageAttr> => {
  const authRequest = useFetch();

  return useInfiniteQuery<DeliveredOrderPageAttr>(
    ordersKey.deliveredOrdersInfiniteList(filters),
    async ({ pageParam: page = 1 }: { pageParam?: number }) => {
      const response = await authRequest.post<DeliveredOrderPageAttr>(
        `${ORDER_ENDPOINT}`,
        {
          ...filters,
          status: ["D"],
          page,
          limit: 10,
        }
      );

      return response.data;
    },
    {
      ...config,
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < Math.ceil(lastPage.pagination.count / 10)
          ? allPages.length + 1
          : undefined,
    }
  );
};

type TrackingHistoryAttr = {
  carrier_id: string;
  order_id: string;
  movement_id: string;
  latitude: number;
  longitude: number;
  located_at: string;
  current_stop_id: string | null;
  estimated_delivery_date: string;
  current_city_id: number;
  next_stop_city_id: number | null;
  city_name: string;
  state_id: string;
  county: string;
  zip_code: string | null;
};

type TrackingHistoryPageAttr = {
  data: Array<TrackingHistoryAttr>;
  pagination: {
    count: number;
    limit: number;
    page: number;
  };
};

type UseTrackingHistoryParams = {
  movement_id: string;
};

const useInfiniteTrackingHistory = (
  params?: UseTrackingHistoryParams,
  config?: UseInfiniteQueryOptions<TrackingHistoryPageAttr>
): UseInfiniteQueryResult<TrackingHistoryPageAttr> => {
  const authRequest = useFetch();

  return useInfiniteQuery<TrackingHistoryPageAttr>(
    ordersKey.trackingHistoryInfiniteList(params),
    async ({ pageParam: page = 1 }: { pageParam?: number }) => {
      const response = await authRequest.get<TrackingHistoryPageAttr>(
        `${ORDER_ENDPOINT}/lanes/history`,
        {
          params: {
            ...params,
            page,
            limit: 10,
          },
        }
      );

      return response.data;
    },
    {
      ...config,
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < Math.ceil(lastPage.pagination.count / 10)
          ? allPages.length + 1
          : undefined,
    }
  );
};

const useDownloadPodAndBolDocument = (): UseMutationResult<
  void,
  unknown,
  string
> => {
  const authRequest = useFetch();

  return useMutation(async (orderNumber: string) => {
    const response = await authRequest.get<string>(
      `${ORDER_ENDPOINT}/${orderNumber}/files/bol-pod`,
      { responseType: "blob" }
    );
    downloadFile(response.data, `OrderNo${orderNumber ?? ""}.pdf`);
  });
};

type OrderLaneAttr = {
  order_id: string;
  movement_id: string;
  origin: {
    arrive_early_datetime: Date;
    arrive_early_datetime_offset: number;
    arrive_late_datetime: Date;
    arrive_late_datetime_offset: number;
    city: string;
    state_code: string;
    latitude: number;
    longitude: number;
  };
  destination: {
    arrive_early_datetime: Date;
    arrive_early_datetime_offset: number;
    arrive_late_datetime: Date;
    arrive_late_datetime_offset: number;
    city: string;
    state_code: string;
    latitude: number;
    longitude: number;
  };
  order_location: {
    latitude: number;
    longitude: number;
    city_name: string;
    state_id: string;
    located_at: Date;
  };
};

type UseOrderLanesFilters = Partial<{
  reference: number | string;
  equipment_type: Array<EquipmentTypesAttr>;
  origin: Array<string> | string;
  destination: Array<string> | string;
  customer_id: string;
  arrive_lane_date_range: Array<Date | null>;
}>;

const useOrderLanes = (
  filters?: UseOrderLanesFilters,
  config?: UseQueryOptions<Array<OrderLaneAttr>>
): UseQueryResult<Array<OrderLaneAttr>> => {
  const authRequest = useFetch();

  return useQuery<Array<OrderLaneAttr>>(
    ordersKey.ordersLanesList(filters),
    async () =>
      (
        await authRequest.post<ResponseAttr<Array<OrderLaneAttr>>>(
          `${ORDER_ENDPOINT}/lanes/current`,
          {
            ...filters,
            status_to_exclude: ["A", "D", "V"],
          }
        )
      ).data.data,
    config
  );
};

type FilesAvailableAttr = {
  documentExists: boolean;
};

const useFilesAvailable = (
  order_number: string,
  config?: UseQueryOptions<FilesAvailableAttr>
): UseQueryResult<FilesAvailableAttr> => {
  const authRequest = useFetch();

  return useQuery<FilesAvailableAttr>(
    ordersKey.filesAvailable(order_number),
    async () =>
      (
        await authRequest.get<ResponseAttr<FilesAvailableAttr>>(
          `${ORDER_ENDPOINT}/${order_number}/files/bol-pod/availability`
        )
      ).data.data,

    config
  );
};

type OrderStatusAttr = {
  final_grouped_status: string;
  title: string;
  sequence: number;
};

const useOrderStatus = (
  config?: UseQueryOptions<Array<OrderStatusAttr>>
): UseQueryResult<Array<OrderStatusAttr>> => {
  const authRequest = useFetch();

  return useQuery<Array<OrderStatusAttr>>(
    ordersKey.orderStatus(),
    async () =>
      (
        await authRequest.get<ResponseAttr<Array<OrderStatusAttr>>>(
          `${ORDER_ENDPOINT}/grouped-status`
        )
      ).data.data,
    config
  );
};

export type {
  DeliveredOrderAttr,
  DeliveredOrderPageAttr,
  OrderAttr,
  OrderPageAttr,
  UseDeliveredOrdersFilters,
  UseOrdersFilters,
  TrackingHistoryAttr,
  TrackingHistoryPageAttr,
  OrderLaneAttr,
  UseOrderLanesFilters,
  OrderStopAttr,
};

export {
  useOrder,
  useOrderLanes,
  useInfiniteDeliveredOrders,
  useInfiniteOrders,
  useInfiniteTrackingHistory,
  useDownloadPodAndBolDocument,
  useFilesAvailable,
  useOrderStatus,
  ordersKey,
};
