import { FullPageErrorFallback } from "@components/full-page-error-fallback";
import { FullPageSpinner } from "@edgelogistics/web-ui";

import { QueryClient } from "react-query";
import { ResponseAttr } from "@services/common/types";
import { publicRequest } from "@utils/public-request";
import { useAsync } from "@utils/hooks/use-async";
// eslint-disable-next-line import/no-namespace -- This is to avoid auth-provider methods collapsing with auth-context methods
import * as authProvider from "@utils/auth-provider";
import {
  MaintenanceI,
  maintenancesData,
} from "@utils/hooks/use-maintenance-times";
import { RoleAttr, ScopeAttr, UserAttr, useUser } from "@services/users";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

const getUserData = async (access_token: string): Promise<UserAttr> => {
  const response = await publicRequest.get<ResponseAttr<UserAttr>>(
    `api/v1/users/me`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );

  return response.data.data;
};

const bootstrapAppData = async (): Promise<UserAttr | null> => {
  const auth = authProvider.getAuthFromCache();

  if (auth) {
    try {
      const user = await getUserData(auth.access_token);
      const { default: logger } = await import(
        /* webpackChunkName: "logger" */ "@utils/logger"
      );
      logger.identifyUser(user);

      return user;
    } catch (error) {
      authProvider.removeAuthInCache();

      return null;
    }
  }

  return null;
};

type AuthContextState = {
  user: UserAttr | null;
  setData: (data: UserAttr | null) => void;
  isAuthorized: (
    permissions: Partial<{
      allowedScopes: Array<ScopeAttr>;
      allowedRoles: Array<RoleAttr["role_id"]>;
    }>
  ) => boolean;
};

const AuthContext = createContext<AuthContextState | null>(null);

if (process.env.NODE_ENV === "development") {
  AuthContext.displayName = "AuthContext";
}

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const { run, setData, ...userQuery } = useAsync<UserAttr | null>();
  const { run: runMaintenance, data } = useAsync<MaintenanceI | null>();

  useEffect(() => {
    run(bootstrapAppData());
    runMaintenance(maintenancesData());
  }, [run, runMaintenance]);

  const permissions_to_remove = useMemo(
    () =>
      data && data?.active
        ? data.functionalities_to_block.map(
            ({ permission_id }) => permission_id
          )
        : [],
    [data]
  );

  const userPermissions = useMemo(() => {
    if (!userQuery.data) {
      return null;
    }

    return [
      ...userQuery.data.role.permissions,
      ...userQuery.data.additional_permissions,
    ]
      .map(({ permission_id }) => permission_id)
      .filter(
        (permission_id) => !permissions_to_remove.includes(permission_id)
      );
  }, [permissions_to_remove, userQuery.data]);

  const isAuthorized = useCallback(
    ({
      allowedScopes,
      allowedRoles,
    }: Partial<{
      allowedScopes: Array<ScopeAttr>;
      allowedRoles: Array<RoleAttr["name"]>;
    }>) => {
      if (!userQuery.data || !userPermissions) {
        return false;
      }

      return (
        (allowedScopes?.some((allowedScope) =>
          userPermissions.includes(allowedScope)
        ) ??
          true) &&
        (allowedRoles?.includes(userQuery.data.role.role_id) ?? true)
      );
    },
    [userPermissions, userQuery.data]
  );

  if (userQuery.status === "resolved") {
    return (
      <AuthContext.Provider
        value={{ user: userQuery.data, setData, isAuthorized }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  if (userQuery.status === "rejected") {
    return <FullPageErrorFallback error={userQuery.error} />;
  }

  return <FullPageSpinner />;
};

const login = async (
  setData: (data: UserAttr | null) => void,
  credentials: Parameters<typeof authProvider.login>[0]
): Promise<void> => {
  const auth = await authProvider.login(credentials);
  const user = await getUserData(auth.access_token);
  setData(user);
};

const logout = (
  setData: (data: UserAttr | null) => void,
  queryClient: QueryClient
): void => {
  queryClient.clear();
  authProvider.removeAuthInCache();
  setData(null);
};

const useAuth = (): AuthContextState => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};

const useCurrentUser = (): UserAttr => {
  const { user, setData } = useAuth();

  if (user) {
    const userQuery = useUser({
      initialData: user,
      onSuccess: (data) => setData(data),
    });

    if (userQuery.isSuccess) {
      return userQuery.data;
    }
  }

  throw new Error("useCurrentUser must be used after a succesful user's login");
};
export { AuthProvider, useAuth, login, logout, useCurrentUser };
export type { AuthContextState };
