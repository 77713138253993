import { isEqual } from "lodash";
import { useFormContext } from "../use-form-context";
import { useSelect } from "./context";
import { useWatch } from "react-hook-form";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { memo, useState } from "react";

type OptionProps = {
  value: unknown;
  children?: React.ReactNode;
};

const OptionInner = ({ children, value }: OptionProps): React.ReactElement => {
  const { name, getOptionLabel, handleClose, onChange } = useSelect();

  const { control, setValue } = useFormContext();

  const list =
    (useWatch({
      control,
      name,
    }) as Array<unknown>) || [];

  const [selected, setSelected] = useState(!!isEqual(list, value));

  return (
    <ListItem disablePadding className="w-[inherit]">
      <ListItemButton
        className={`w-[inherit] py-2 ${selected ? "bg-gray-100" : ""}`}
        onClick={() => {
          setSelected(!selected);
          setValue(name, value, { shouldValidate: true, shouldTouch: true });
          handleClose();
          onChange ? onChange(value) : null;
        }}
        dense
      >
        <ListItemText
          primary={
            children ?? (
              <p className="truncate w-full leading-none text-gray-500">
                {value ? getOptionLabel(value) : ""}
              </p>
            )
          }
          className="max-w-[13rem]"
        />
      </ListItemButton>
    </ListItem>
  );
};

export const Option = memo(OptionInner);
