import { twMerge } from "tailwind-merge";
import { useFormContext } from "./use-form-context";
import { Controller, RegisterOptions } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";

type InputProps = Partial<{
  defaultValue: string | number;
  rules: RegisterOptions;
  classNames: Partial<{
    input: string;
    error: string;
    container: string;
  }>;
  type: React.HTMLInputTypeAttribute;
  variant: "standard" | "filled" | "outlined";
  size: "small" | "medium";
  multiline?: boolean;
  placeholder: string;
  rows?: number;
  label: React.ReactNode;
  rootInputProps: Partial<{
    className: string;
  }>;
  inputProps: Partial<{
    startAdornment: React.ReactNode;
    endAdornment: React.ReactNode;
    disableUnderline: boolean;
  }>;
  required: boolean;
  disabled: boolean;
  transform: {
    input: (value: string) => string;
    output: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  };
}> & { name: string };

const Input = ({
  type = "text",
  name,
  label,
  rules,
  classNames,
  rootInputProps,
  inputProps,
  placeholder,
  defaultValue,
  required = false,
  disabled = false,
  variant = "standard",
  size = "medium",
  multiline = false,
  rows = 1,
  transform = {
    input: (value: string) => value,
    output: (event: React.ChangeEvent<HTMLInputElement>) => event,
  },
}: InputProps): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, isTouched },
      }) => (
        <FormControl
          error={Boolean(error && isTouched)}
          className={classNames?.container}
        >
          <TextField
            disabled={disabled}
            error={Boolean(error && isTouched)}
            value={transform.input(value as string)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(transform.output(event))
            }
            autoComplete="off"
            id={name}
            type={type}
            label={label}
            variant={variant}
            size={size}
            multiline={multiline}
            className={classNames?.input}
            placeholder={placeholder}
            inputProps={rootInputProps}
            InputProps={inputProps}
            rows={rows}
            InputLabelProps={{
              required,
              classes: {
                asterisk: variant === "standard" ? "hidden" : "text-red",
              },
            }}
          />
          <FormHelperText
            className={twMerge(
              `text-xs font-semibold text-red ml-0 truncate h-4 ${
                error?.message ? "visible" : "invisible"
              }`,
              classNames?.error
            )}
          >
            {error?.message}
          </FormHelperText>
          {required && variant === "standard" ? (
            <span
              className={`text-sm text-red absolute right-0 ${
                label ? "top-4" : "top-0"
              }`}
            >
              *
            </span>
          ) : null}
        </FormControl>
      )}
      name={name}
      defaultValue={defaultValue || ""}
    />
  );
};

export { Input };
