const downloadFile = (blob: string, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");

  link.setAttribute("href", url);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};

export { downloadFile };
