import { Chips } from "./chips";
import { SelectEndAdornment } from "./select-end-adornment";
import { SelectProvider } from "./context";
import { SelectStartAdornment } from "./select-start-adornment";
import { isNil } from "lodash";
import { twMerge } from "tailwind-merge";
import { useFormContext } from "../use-form-context";
import { Controller, ControllerFieldState } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  List,
  Popover,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";

type SelectProps = Partial<{
  label: string;
  placeholder: string;
  autoFocus: boolean;
  onChange: (value?: unknown) => void;
  limit: number;
  required: boolean;
  className: string;
  classNames: Partial<{
    container: string;
    chipLabel: string;
  }>;
  rootInputProps: Partial<{
    className: string;
  }>;
  onClose: () => void;
  onLimit: () => void;
  size: "small" | "medium";
  startIcon: React.ReactNode;
  onInputChange: (
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}> & {
  name: string; // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel: (data: any) => string;
};

export const Select = ({
  name,
  label,
  onLimit,
  onClose,
  onChange,
  children,
  startIcon,
  className,
  classNames,
  limit = 5,
  onInputChange,
  getOptionLabel,
  placeholder,
  rootInputProps,
  autoFocus = false,
  required = false,
  size = "medium",
}: React.PropsWithChildren<SelectProps>): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }

    setAnchorEl(null);
  };

  const { control } = useFormContext();

  const input = useCallback(
    ({
      error,
      isTouched,
      value,
    }: ControllerFieldState & { value: unknown }) => (
      <FormControl
        error={!!(error && isTouched)}
        fullWidth
        className={classNames?.container}
      >
        <TextField
          fullWidth
          error={!!(error && isTouched)}
          label={label}
          placeholder={
            isNil(value) || (Array.isArray(value) && value.length === 0)
              ? placeholder
              : ""
          }
          variant="standard"
          onClick={handleClick}
          disabled
          autoFocus={autoFocus}
          size={size}
          inputProps={rootInputProps}
          InputLabelProps={{
            required,
            classes: {
              asterisk: "text-red",
            },
          }}
          InputProps={{
            startAdornment: (
              <SelectStartAdornment
                startIcon={startIcon}
                classNames={{
                  chipLabel: classNames?.chipLabel,
                }}
              />
            ),
            endAdornment: <SelectEndAdornment />,
            onClick: handleClick,
          }}
        />
        <FormHelperText
          className={`text-xs font-semibold text-red ml-0 truncate h-4 ${
            error?.message ? "visible" : "invisible"
          }`}
        >
          {error?.message}
        </FormHelperText>
      </FormControl>
    ),
    [
      classNames?.container,
      classNames?.chipLabel,
      label,
      placeholder,
      size,
      rootInputProps,
      required,
      startIcon,
      autoFocus,
    ]
  );

  return (
    <SelectProvider
      name={name}
      getOptionLabel={getOptionLabel}
      handleClose={handleClose}
      limit={limit}
      onLimit={onLimit}
      onChange={onChange}
    >
      <Controller
        control={control}
        render={({ fieldState, field: { value } }) => (
          <div className="w-full relative">
            {required ? (
              <span
                className={`text-sm text-red absolute right-0 ${
                  label ? "top-4" : "top-0"
                }`}
              >
                *
              </span>
            ) : null}
            {input({ ...fieldState, value })}

            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              className="z-[1300] select-popover"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                className: "max-h-screen",
              }}
            >
              <div className={twMerge("relative p-3", className)}>
                <div className="static z-50 bg-white">
                  <TextField
                    fullWidth
                    placeholder="Search..."
                    variant="outlined"
                    onChange={onInputChange}
                    autoFocus={autoFocus}
                    InputProps={{
                      classes: {
                        root: "h-10",
                        input: "text-sm text-gray-700",
                      },
                    }}
                  />
                </div>
                <Chips />
                <List className={`bg-white w-full h-60 overflow-y-auto`}>
                  {children}
                </List>
              </div>
            </Popover>
          </div>
        )}
        name={name}
      />
    </SelectProvider>
  );
};
