import {
  UseFormReturn,
  useFormContext as useFormContextHF,
} from "react-hook-form";

export const useFormContext = <
  T extends Record<string, unknown>
>(): UseFormReturn<T> => {
  return useFormContextHF<T>();
};
