import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/lab";
import { DatePicker as MDatePicker } from "@mui/lab";
import { useFormContext } from "./use-form-context";
import { useState } from "react";
import { Controller, RegisterOptions } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";

type DatePickerProps = Partial<{
  rules: RegisterOptions;
  variant: "standard" | "filled" | "outlined";
  placeholder: string;
  label: React.ReactNode;
  startAdornment: React.ReactNode;
  classNames: Partial<{
    input: string;
    inputRoot: string;
    datepicker: string;
    container: string;
  }>;
  required: boolean;
  disabled: boolean;
}> & { name: string };

export const DatePicker = ({
  name,
  label,
  rules,
  classNames,
  placeholder,
  startAdornment,
  required = false,
  variant = "standard",
  disabled = false,
}: DatePickerProps): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, isTouched },
      }) => (
        <FormControl
          error={Boolean(error && isTouched)}
          className={classNames?.container}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MDatePicker<Dayjs>
              onChange={(value) => {
                onChange(value?.toDate());
              }}
              value={value as Dayjs}
              label={label}
              disabled={disabled}
              PopperProps={{ style: { zIndex: 1300 } }}
              open={open}
              onClose={() => setOpen(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  value={value}
                  error={Boolean(error && isTouched)}
                  label={label}
                  variant={variant}
                  InputLabelProps={{
                    required,
                    classes: {
                      asterisk: "text-red",
                    },
                  }}
                  onClick={() => setOpen(!disabled)}
                  className={classNames?.input}
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "off",
                    startAdornment,
                    endAdornment: null,
                    classes: {
                      root: classNames?.inputRoot,
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText
            className={`text-xs font-semibold text-red ml-0 truncate h-4 ${
              error?.message ? "visible" : "invisible"
            }`}
          >
            {error?.message}
          </FormHelperText>
          {required && variant === "standard" ? (
            <span className="text-sm text-red absolute top-0 right-0">*</span>
          ) : null}
        </FormControl>
      )}
      name={name}
      defaultValue={""}
    />
  );
};
