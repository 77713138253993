import { ResponseAttr } from "./common/types";
import { useFetch } from "@context/fetch";
import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

const EVENTS_ENDPOINT = "/api/v1/orders/events";

const weekCalendarKey = {
  all: ["week-calendar"] as const,
  weekCalendarParams: (params: UseCalendarDateParams) =>
    [...weekCalendarKey.all, params] as const,
};

type EventAttr = {
  type: string;
  arrive_early_datetime: string | null;
  arrive_early_datetime_offset: number | null;
  arrive_late_datetime: string | null;
  arrive_late_datetime_offset: number | null;
  address: string | null;
  location: string | null;
  city: string | null;
  state: string | null;
  state_code: string | null;
  zip_code: string | null;
};

type CalendarDateAttr = {
  date: string;
  events: Array<EventAttr>;
};

type UseCalendarDateParams = {
  events_start_date: string;
  events_end_date: string;
  customer_id?: string | null;
  salesperson_id?: string | null;
};

const useWeekCalendarQuery = (
  params: UseCalendarDateParams,
  config?: UseQueryOptions<Array<CalendarDateAttr>>
): UseQueryResult<Array<CalendarDateAttr>> => {
  const authRequest = useFetch();

  return useQuery<Array<CalendarDateAttr>>(
    weekCalendarKey.weekCalendarParams(params),
    async () =>
      (
        await authRequest.get<ResponseAttr<Array<CalendarDateAttr>>>(
          EVENTS_ENDPOINT,
          {
            params,
          }
        )
      ).data.data,
    config
  );
};

export type { CalendarDateAttr, EventAttr };

export { useWeekCalendarQuery, weekCalendarKey };
