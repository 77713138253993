import { useCallback, useEffect, useState } from "react";

export const useThemeDetector = (): boolean => {
  const getMatchMedia = useCallback(
    (): MediaQueryList => window.matchMedia("(prefers-color-scheme: dark)"),
    []
  );
  const [isDarkTheme, setIsDarkTheme] = useState(getMatchMedia().matches);

  const mediaQueryListener = useCallback(
    (matches: boolean): void => setIsDarkTheme(matches),
    []
  );

  useEffect(() => {
    const mediaQuery = getMatchMedia();

    mediaQuery.addEventListener("change", (e) => mediaQueryListener(e.matches));

    return () =>
      mediaQuery.removeEventListener("change", (e) =>
        mediaQueryListener(e.matches)
      );
  }, [getMatchMedia, mediaQueryListener]);

  return isDarkTheme;
};
